<template>
  <div>
    <Dialog
      v-model:visible="showCreateProductDialog"
      :draggable="false"
      :modal="true"
      style="width: 80%"
    >
      <template #header>
        <h2 class="fw-normal">Buat Produk</h2>
      </template>
      <CreateProduk />
    </Dialog>
    <button
      class="btn btn-light border rounded-0 d-block me-auto mb-4"
      @click="createProduk"
    >
      Buat Produk
    </button>
    <ProdukTable />
  </div>
</template>

<script>
import ProdukTable from "@/components/Produk/ProdukTable.vue";
import CreateProduk from "@/components/Produk/CreateProduk.vue";
export default {
  name: "ProdukView",
  components: {
    ProdukTable,
    CreateProduk
  },
  data() {
    return {
      showCreateProductDialog: false,
    }
  },
  methods: {
    createProduk() {
      this.showCreateProductDialog = true;
    }
  }
}
</script>

<style>

</style>