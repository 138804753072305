<template>
  <form>
    <div class="form-group mb-4">
      <label for="nama" class="fw-bold mb-2">Nama Produk</label>
      <input type="text" class="form-control rounded-0" />
    </div>
    <div class="form-group mb-4">
      <label for="nama" class="fw-bold mb-2">SKU</label>
      <input type="text" class="form-control rounded-0" />
    </div>
    <div class="form-group mb-4">
      <label for="nama" class="fw-bold mb-2">Price List</label>
      <input type="number" class="form-control rounded-0" />
    </div>
    <button
      type="submit"
      class="btn btn-light rounded-0 border d-block ms-auto"
    >
      Submit
    </button>
  </form>
</template>

<script>
export default {
  data() {
    return {
    };
  },
};
</script>

<style></style>
