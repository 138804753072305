<template>
  <Dialog
    v-model:visible="showFilterDialog"
    header="Filter"
    style="width: 75%"
    :modal="true"
    :draggable="true"
  >
    <ProdukFilter />
  </Dialog>
  <button
    class="btn btn-ligth btn-sm d-block ms-auto rounded-0 border"
    @click="showFilterDialog = true"
  >
    Filter
  </button>
  <DataTable :value="products" responsiveLayout="scroll" :scrollable="true">
    <Column
      v-for="col in columns"
      :key="col.field"
      :field="col.field"
      style="min-width: 150px"
    >
      <template #header>
        <span style="font-size: 0.9rem">{{ col.header }}</span>
      </template>
    </Column>
    <Column alignFrozen="right" frozen>
      <template #body>
        <h5 class="p-1" style="cursor: pointer" @click="toggleMenu">
          <i class="fa-solid fa-ellipsis-vertical"></i>
        </h5>
        <Menu ref="menu" :model="menuItems" :popup="true">
          <template #item="{ item }">
            <div
              :class="item.class"
              class="cursor-pointer"
              @click="item.atClick"
            >
              {{ item.label }}
            </div>
          </template>
        </Menu>
      </template>
    </Column>
    <template #empty>
      <span>Tidak ada data produk</span>
    </template>
  </DataTable>
</template>

<script>
import axios from "axios";
import ProdukFilter from "@/components/Produk/ProdukFilter.vue";

export default {
  name: "KontakList",
  components: { ProdukFilter },
  data() {
    return {
      columns: [
        { field: "name", header: "Nama Produk" },
        { field: "sku", header: "SKU" },
        { field: "price_list", header: "Price List" },
        { field: "existing_company", header: "Perusahaan Existing" },
        { field: "pipeline", header: "Pipeline" },
        { field: "prospect", header: "Prospek" },
        {
          field: "campaign",
          header: "Campaign",
        },
      ],
      products: [],
      menuItems: [
        {
          label: "Detail",
          class: "m-2 text-decoration-none text-black d-block",
          atClick: () => {
            this.$emit("detail");
          },
        },
        {
          label: "Edit",
          class: "m-2 text-decoration-none text-black d-block",
          atClick: () => {
            this.$emit("edit");
          },
        },
        {
          label: "Hapus",
          class: "m-2 my-4 text-decoration-none text-danger d-block",
          atClick: () => {
            this.$emit("delete");
          },
        },
      ],
      showFilterDialog: false,
    };
  },
  methods: {
    createProduk() {
      this.$emit("create-produk");
    },
    toggleMenu(event) {
      this.$refs.menu.toggle(event);
    },
    async fetchProduct(filter = {}) {
      try {
        const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/product`, {
          params: filter,
          headers: {authorization: `Bearer ${this.user.token}`}
        });

        if (res.status === 200) {
          this.products = res.data.result;
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
  async created() {
    await this.fetchProduct();
  },
};
</script>
