<template>
  <form>
    <div class="form-group">
      <label for="status" class="fw-bold mb-2">Status Produk</label>
      <select name="status" class="form-select rounded-0"></select>
    </div>
  </form>
</template>

<script>
export default {};
</script>

<style></style>
