<template>
  <div id="app">
    <router-view />

    <!-- TOAST -->
    <Toast position="bottom-right" />
  </div>
</template>

<script>
  export default {};
</script>

<style>
  #app {
    font-family: Inter, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* text-align: center; */
    /* color: #2c3e50; */
    max-width: 100vw;
    box-sizing: border-box;
  }

  * {
    font-family: Inter, sans-serif;
    box-sizing: border-box;
  }

  nav {
    padding: 30px;
  }

  nav a {
    font-weight: bold;
    color: #2c3e50;
  }

  /* nav a.router-link-exact-active {
  color: #42b983;
} */

  .cursor-pointer {
    cursor: pointer;
  }

  .custom-datatable-2 .p-datatable-wrapper thead.p-datatable-thead th,
  .custom-datatable-2 .p-datatable-header {
    background: #eff3f8 !important;
  }

  .custom-datatable-2 .p-datatable-wrapper thead.p-datatable-thead th,
  .custom-datatable-2 .p-datatable-wrapper tbody.p-datatable-tbody td {
    font-size: 0.9rem;
  }

  .custom-multiselect .p-multiselect-label.p-placeholder {
    color: white !important;
  }

  .custom-multiselect .p-multiselect-trigger {
    color: white !important;
  }

  .p-button-primary {
    background: #0170b9 !important;
    border: #0170b9 !important;
  }

  .p-button-primary.p-button-outlined {
    background: white !important;
    border: 2px solid #0170b9 !important;
    color: #0170b9 !important;
    animation: 0.1s;
  }

  .p-button-primary.p-button-outlined:hover {
    background: #0170b9 !important;
    color: white !important;
  }

  .p-button-danger {
    background: #e73329 !important;
    border: #e73329 !important;
  }

  .p-button-danger.p-button-outlined {
    background: white !important;
    border: 2px solid #e73329 !important;
    color: #e73329 !important;
    animation: 0.1s;
  }

  .p-button-danger.p-button-outlined:hover {
    background: #e73329 !important;
    color: white !important;
  }

  .custom-card .p-card-content,
  .custom-card .p-card-body {
    padding: 0 !important;
  }

  .p-menuitem-text {
    color: rgb(73, 80, 87) !important;
  }
  .p-submenu-icon {
    order: 3;
    margin-left: auto;
    color: rgb(73, 80, 87) !important;
  }

  .p-menuitem-icon {
    color: rgb(73, 80, 87) !important;
  }

  .p-panelmenu-header-content {
    background: white !important;
    border: 0 !important;
  }
  .p-panelmenu-header-action {
    background-color: white !important;
    display: flex !important;
  }
  .p-panelmenu-content {
    background-color: white !important;
    border: 0 !important;
  }

  .p-panelmenu {
    border: 0 !important;
  }
</style>
