import { createRouter, createWebHashHistory } from "vue-router";
import ProdukList from "@/views/Produk/List.vue";

const routes = [
  {
    path: "/",
    redirect: { name: "dashboard" },
    component: () => import("@/layout/ContentLayout"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard/DashboardMain"),
      },

      {
        path: "/customer/detail/:id",
        name: "customer-detail",
        component: () =>
          import("@/views/CustomerAndDistributor/Customer/CustomerDetail"),
      },
      {
        path: "/customer-contact/detail/:id",
        name: "kontak-customer-detail",
        component: () =>
          import(
            "@/views/CustomerAndDistributor/Customer/KontakCustomerDetail"
          ),
      },
      {
        path: "/distributor/detail/:id",
        name: "distributor-detail",
        component: () =>
          import(
            "@/views/CustomerAndDistributor/Distributor/DistributorDetail"
          ),
      },
      {
        path: "/database/customer",
        name: "database-customer",
        component: () =>
          import("@/views/CustomerAndDistributor/Customer/CustomerMain"),
      },
      {
        path: "/database/customer-contact/detail/:id",
        name: "kontak-customer-detail",
        component: () =>
          import(
            "@/views/CustomerAndDistributor/Customer/KontakCustomerDetail"
          ),
      },
      {
        path: "/database/distributor",
        name: "database-distributor",
        component: () =>
          import("@/views/CustomerAndDistributor/Distributor/DistributorMain"),
      },
      {
        path: "/database/distributor-contact/detail/:id",
        name: "kontak-distributor-detail",
        component: () =>
          import(
            "@/views/CustomerAndDistributor/Distributor/KontakDistributorDetail"
          ),
      },
      {
        path: "/projects/forecast",
        name: "projects-forecast",
        component: () => import("@/views/Projects/RenewalList"),
      },
      {
        path: "/projects/pipeline",
        name: "projects-pipeline",
        component: () => import("@/views/Projects/Pipeline"),
      },
      {
        path: "/projects/leads",
        name: "projects-leads",
        component: () => import("@/views/Projects/LeadProjectList"),
      },
      {
        path: "/projects/existing-customer",
        name: "projects-existing-customer",
        component: () => import("@/views/Projects/ExistingCustomerProject"),
      },
      {
        path: "/pipeline/detail/:id",
        name: "pipeline-detail",
        component: () => import("@/views/Pipeline/PipelineDetail.vue"),
      },
      {
        path: "/produk",
        name: "produk-list",
        component: ProdukList,
      },
      {
        path: "/pengaturan/user",
        name: "user",
        component: () => import("@/views/Pengaturan/User/UserPage.vue"),
      },
      {
        path: "/pengaturan/role/add",
        name: "add-new-role",
        component: () =>
          import("@/components/Pengaturan/User/Role/Modals/AddRole"),
      },
      {
        path: "/pengaturan/role/edit/:id",
        name: "edit-role",
        component: () =>
          import("@/components/Pengaturan/User/Role/Modals/EditRole"),
      },
      {
        path: "/pengaturan/custom-fields",
        name: "custom-fields",
        component: () =>
          import("@/views/Pengaturan/CustomFields/CustomFieldsIndex.vue"),
      },
      {
        path: "/pengaturan/custom-fields/1",
        name: "custom-fields-1-index",
        component: () =>
          import(
            "@/views/Pengaturan/CustomFields/IndustriDataDanJabatan/Index.vue"
          ),
      },
      {
        path: "/pengaturan/custom-fields/2",
        name: "custom-fields-2-index",
        component: () =>
          import("@/views/Pengaturan/CustomFields/DataNamaBarang/Index.vue"),
      },
      {
        path: "/pengaturan/custom-fields/3",
        name: "custom-fields-3-index",
        component: () =>
          import("@/views/Pengaturan/CustomFields/SetTarget/TargetList.vue"),
      },
      {
        path: "/pengaturan/custom-fields/4",
        name: "custom-fields-4-index",
        component: () =>
          import(
            "@/views/Pengaturan/CustomFields/CustomLabelKalender/CustomLabelKalender.vue"
          ),
      },
      {
        path: "/pengaturan/custom-fields/5",
        name: "custom-fields-5-index",
        component: () =>
          import("@/views/Pengaturan/CustomFields/Quadrant/QuadrantList.vue"),
      },
      {
        path: "/pengaturan/custom-fields/6",
        name: "custom-fields-6-index",
        component: () =>
          import(
            "@/views/Pengaturan/CustomFields/StatusDanNextAction/Index.vue"
          ),
      },
      {
        path: "/pengaturan/custom-fields/atribut-pipeline",
        name: "custom-fields-atribut-pipeline",
        component: () =>
          import(
            "@/views/Pengaturan/CustomFields/AtributPipeline/AtributPipeline.vue"
          ),
      },
      {
        path: "/pengaturan/custom-fields/email-account",
        name: "custom-fields-email-account",
        component: () =>
          import("@/views/Pengaturan/CustomFields/EmailAccount/Index.vue"),
      },
      {
        path: "/request-demo/detail/:id",
        name: "request-demo-detail",
        component: () => import("@/views/RequestDemo/RequestDemoDetail.vue"),
      },
      {
        path: "/add-license-request/:pipelineId",
        name: "add-license-request-poc",
        component: () => import("@/views/LicenseRequest/AddLicenseRequest.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login"),
    meta: { requiresUnauth: true },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to) => {
  // instead of having to check every route record with
  // to.matched.some(record => record.meta.requiresAuth)
  if (to.meta.requiresAuth) {
    const user = JSON.parse(localStorage.getItem("user"));

    if (!user || !user.expired || user.expired < new Date().getTime()) {
      return {
        path: "/login",
        // save the location we were at to come back later
        // query: { redirect: to.fullPath },
      };
    }
  }
});

export default router;
