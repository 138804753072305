/* eslint-disable vue/multi-word-component-names */
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

// Primevue
import PrimeVue from "primevue/config";
import "primevue/resources/themes/saga-blue/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import "primeflex/primeflex.css";
// import "prismjs/themes/prism-coy.css";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Button from "primevue/button";
import Card from "primevue/card";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import DataTable from "primevue/datatable";
import Menu from "primevue/menu";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import MultiSelect from "primevue/multiselect";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import InputSwitch from "primevue/inputswitch";
import Textarea from "primevue/textarea";
import DropDown from "primevue/dropdown";
import ToastService from "primevue/toastservice";
import Tag from "primevue/tag";
import Toast from "primevue/toast";
import TieredMenu from "primevue/tieredmenu";
import Timeline from "primevue/timeline";
import ConfirmationService from "primevue/confirmationservice";
import ConfirmDialog from "primevue/confirmdialog";
import Calendar from "primevue/calendar";
import ColorPicker from "primevue/colorpicker";
import Toolbar from "primevue/toolbar";
import Chart from "primevue/chart";
import Divider from "primevue/divider";
import Checkbox from "primevue/checkbox";
import PanelMenu from "primevue/panelmenu";

// Datepicker
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

// Vue Simple Calendar
import { CalendarView, CalendarViewHeader } from "vue-simple-calendar";
import "../node_modules/vue-simple-calendar/dist/style.css";
import "../node_modules/vue-simple-calendar/dist/css/default.css";

const app = createApp(App);

// Primevue components
app.use(PrimeVue);
app.use(ToastService);
app.use(ConfirmationService);
app.component("Accordion", Accordion);
app.component("AccordionTab", AccordionTab);
app.component("Dialog", Dialog);
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("Menu", Menu);
app.component("Button", Button);
app.component("TabView", TabView);
app.component("TabPanel", TabPanel);
app.component("TieredMenu", TieredMenu);
app.component("Timeline", Timeline);
app.component("Loading", Loading);
app.component("InputText", InputText);
app.component("InputNumber", InputNumber);
app.component("InputSwitch", InputSwitch);
app.component("DropDown", DropDown);
app.component("Card", Card);
app.component("Tag", Tag);
app.component("Textarea", Textarea);
app.component("Toast", Toast);
app.component("ConfirmDialog", ConfirmDialog);
app.component("Calendar", Calendar);
app.component("MultiSelect", MultiSelect);
app.component("ColorPicker", ColorPicker);
app.component("Toolbar", Toolbar);
app.component("Chart", Chart);
app.component("Divider", Divider);
app.component("Checkbox", Checkbox);
app.component("PanelMenu", PanelMenu);

// Datepicker components
app.component("Datepicker", Datepicker);

// Vue Simple Calendar
app.component("CalendarView", CalendarView);
app.component("CalendarViewHeader", CalendarViewHeader);

app.use(router).mount("#app");
